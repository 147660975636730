import { useEffect, useState } from "react";
import { GETInsts } from "../apiCalls/call";
import { _eDefault, eS, eState } from "../apiCalls/metacall";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography } from "@mui/material";
import { PropsOne, useEffectCallAPIAsState } from "../meta";
import { AppHeader, UglySnackbar } from "./SharedComps";



function InstitutionCards({insts}: PropsOne<eState>) {

    const nav = useNavigate();

    const handleCardClick = (inst_id: number) => {
        nav(`/app/${inst_id}`);
    };


    return (

        insts._e.success ? 
        <div>
        {insts.objs.map((institution: any, index: number) => (
          <Card
            key={index}
            style={{ marginBottom: '20px', cursor: 'pointer' }}
            onClick={() => handleCardClick(institution.inst_id)}
          >
            <CardContent>
              <Typography variant="h5">
                {institution.inst_name}
              </Typography>
              <Typography variant="body1">
                {`Your role: ${institution.role_name}`}
              </Typography>
              {institution.dept_roles ? <Typography variant="body2">
                {`Other roles: ${institution.dept_roles.join(', ')}`}
              </Typography> : <></>}

            </CardContent>
          </Card>
        ))}
      </div> : 
        <div></div>

        
    );
};

export function Institutions() {

    const [uglyOpen, setUglyOpen] = useState(_eDefault);

    const insts = useEffectCallAPIAsState(GETInsts, {}, setUglyOpen);

    return (<><InstitutionCards insts = {insts}/><UglySnackbar eDisp = {uglyOpen} setEDisp= {setUglyOpen}/></>)
}

export default function WelcomePage() {
    return (<><AppHeader/><Institutions/></>);
}
import { useParams } from "react-router-dom";
import { eS, errorDisplay } from "../apiCalls/metacall";
import { PropsOne, formatISO8601, useEffectCallAPIAsState } from "../meta";
import { GETInstUserList, GETInstUserListModPerms } from "../apiCalls/call";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";



export default function InstUsers ({setEDisp} : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {
    const {instId} = useParams();
    const instUsers = useEffectCallAPIAsState(GETInstUserList, {inst_id: instId}, setEDisp)
    const instUsersModPerm = useEffectCallAPIAsState(GETInstUserListModPerms, {inst_id: instId}, setEDisp)



    const columns: GridColDef[] = [
        { field: 'first_name', headerName: 'First name', width: 130 },
        { field: 'last_name', headerName: 'Last name', width: 130 },
        { field: 'email', headerName: 'Email address', width: 300},
        { field: 'role_name_EN', headerName: 'Role', width: 130},
        { field: 'time_added_formatted', headerName: 'Time added', width: 300, 
            valueGetter: (params: GridValueGetterParams) => `${formatISO8601(params.row.time_added)}`},
      ];

    return (<div style={{ width: '100%' }}>
    {
        eS.isOK(instUsers) && <DataGrid
        rows={instUsers.objs}
        getRowId={(r) => r.user_id}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 }
          },
        }}
        pageSizeOptions={[10]}
      />
    }
  </div>)
}
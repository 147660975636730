import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { LandingPage } from './landingPage/LandingPage';
import LoginPage from './pageComps/LoginPage';
import SignupPage from './pageComps/SignupPage';
import WelcomePage from './pageComps/WelcomePage';
import InstPage from './pageComps/InstPage';
import InstUsersPage from './pageComps/InstUsersPage';
import DeptPage from './pageComps/DeptPage';
import TicketPage from './pageComps/TicketPage';
import Page from './metaComps/Page';
import { POSTLogin } from './apiCalls/call';
import { eState } from './apiCalls/metacall';

//I shouldn't need to pass anything into createContext (it does nothing since I am using Provider to instantiate it)
//but I am doing it anyway to shut up TS.
export const loginResContext = React.createContext({loginGlobal: {}, setLoginGlobal: {}} as 
                                                    {loginGlobal: eState, setLoginGlobal: React.Dispatch<React.SetStateAction<eState>>});

export default function App() {

  const [loginGlobal, setLoginGlobal] = useState(POSTLogin.newS0());

  return (
    <loginResContext.Provider value = {{loginGlobal: loginGlobal, setLoginGlobal: setLoginGlobal}}>
    <Router>
      <Routes>
      <Route path="/" element={<Page title="Landing" component={LandingPage} />} />
        <Route path="/app/login/?" element={<Page title="Login" component={LoginPage} />} />
        <Route path="/app/signup/?" element={<Page title="Signup" component={SignupPage} />} />
        <Route path="/app/?" element={<Page title="Welcome" component={WelcomePage} />} />
        <Route path="/app/:instId/?" element={<Page title="Institution" component={InstPage} />} />
        <Route path="/app/:instId/users/?" element={<Page title="View users" component={InstUsersPage} />} />
        <Route path="/app/:instId/dept/:deptId/?" element={<Page title="Department" component={DeptPage} />} />
        <Route path="/app/:instId/tickets/:ticketId/?" element={<Page title="Ticket" component={TicketPage} />} />
      </Routes>
    </Router>
    </loginResContext.Provider>
  );
}

import { useNavigate, useParams } from 'react-router-dom';
import { AppHeader, UglySnackbar } from './SharedComps';
import { useEffect, useState } from 'react';
import { _eDefault, eS, eState, errorDisplay, preState } from '../apiCalls/metacall';
import { GETInst, GETInstRoleAndPerms } from '../apiCalls/call';
import { PropsOne, useEffectCallAPIAsState } from '../meta';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Button, Card, CardContent, Grid, List, ListItem, ListItemText, Tab, Tabs, Typography } from '@mui/material';
import { DeptsSummary } from './InstPageDeptBox';
import InstUsers from './InstUsersPage';

//
//Title bar
//

function TitleBarWrapper({setEDisp} : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {

    const {instId} = useParams();
    const instInfo = useEffectCallAPIAsState(GETInstRoleAndPerms, {inst_id: instId}, setEDisp);
    return (<><TitleBar instInfo = {instInfo} setEDisp = {setEDisp}/>
    </>)
}

interface TitleBarProps {
  instInfo: eState,
  setEDisp: React.Dispatch<React.SetStateAction<errorDisplay>>
}

function TitleBar({ instInfo, setEDisp } : TitleBarProps) {

  const nav = useNavigate();
  const {instId} = useParams();

  return (<>
    <div style={{ padding: '10px 30px', backgroundColor: '#f2f2f2' }}>
      {/* Display inst_name in big, thin letters */}
      <h1 style={{ fontWeight: '300', fontSize: '36px' }}>{instInfo.inst_name}</h1>

      {/* Display 'Your role: (role name)' */}
      <p style={{ fontSize: '16px', margin: '5px 0' }}>
        Your role: {instInfo.role_name}
      </p>

      {/* Conditionally display 'Other roles: (dept_roles)' */}
      {instInfo.dept_roles && instInfo.dept_roles.length > 0 && (
        <p style={{ fontSize: '14px', margin: '5px 0' }}>
          Other roles: {instInfo.dept_roles.join(', ')}
        </p>
      )}
    </div>
    <AddDept instInfo = {instInfo}/>
    <InstTabs instInfo = {instInfo} setEDisp = {setEDisp}/></>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel (props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div role = "tabpanel"
      hidden = {value !== index}>
        {value === index &&
          children
        }
      </div>
    );
}

function InstTabs( {instInfo, setEDisp}: TitleBarProps ) {

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (<Box sx={{ width: '100%' }}>
      {instInfo && eS.isOK(instInfo) && 
      <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="Institution-tabs">
          <Tab icon = {<FolderIcon/>} label="Departments" value = {0}/>
          <Tab icon = {<PeopleIcon/>} label="Users" value = {1}/>
          {instInfo && eS.isOK(instInfo) && instInfo.perms.includes('inst.settings.view') && <Tab icon = {<SettingsIcon/>} label="Settings" value = {2}/>}
        </Tabs>
      </Box>
      <TabPanel value = {value} index = {0}><DeptsSummaryWrapper setEDisp = {setEDisp}/></TabPanel>
      <TabPanel value = {value} index = {1}><InstUsers setEDisp = {setEDisp}/></TabPanel>
      <TabPanel value = {value} index = {2}></TabPanel>
      </>
    }
    </Box>)

}

//
//DEPARTMENT SUMMARY SUBCOMPONENTS
//


function DeptsSummaryWrapper({setEDisp} : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {

  const {instId} = useParams();
  const deptsummary = useEffectCallAPIAsState(GETInst, {inst_id: instId}, setEDisp)

  return (<><DeptsSummary data={deptsummary}/></>)
}

function AddDept({instInfo} : PropsOne<eState>) {
  return (<div>

    {/* Conditionally display "Create new department" button */}
    {instInfo.perms.includes('inst.idept.create') && (
      <Button
        variant="contained"
        color="secondary"
        style={{ marginLeft: '10px' }}
        startIcon={<AddIcon />}
      >
        Create new department
      </Button>
    )}
  </div>)
}

export default function InstPage() {


    const [uglyOpen, setUglyOpen] = useState(_eDefault);
    

    return (<><AppHeader/>
              <TitleBarWrapper setEDisp = {setUglyOpen}/>
              <UglySnackbar eDisp = {uglyOpen} setEDisp= {setUglyOpen}/></>);
}
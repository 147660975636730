import { AppBar, Toolbar, Typography, Button, Link, Snackbar, Alert } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { loginResContext } from "../App";
import { GETAuthStatus, POSTLogin, GETAuthUserInfo, POSTLogout } from "../apiCalls/call";
import { _eDefault, eS } from "../apiCalls/metacall";
import { MyErrProps } from "../meta";

//Here, stateAttr is uglyOpen
export function UglySnackbar({eDisp, setEDisp}: MyErrProps) {

    const closeUgly = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setEDisp(_eDefault);
    };
  
      return (
        <Snackbar open={eDisp.err} autoHideDuration={60000} onClose={closeUgly}>
          <Alert onClose={closeUgly} severity="error" sx={{ width: '100%' , minWidth: '300px', minHeight: '100px', fontSize: '1.2em', padding: '20px'}}>
            {eDisp.msg}
          </Alert>
        </Snackbar>
      );
  }

export function AppHeader() {
    
    const {loginGlobal, setLoginGlobal} = useContext(loginResContext); //Has name
    const nav = useNavigate();

    useEffect(() => { //Treat context like a session or cookie.
      const F = async () => {
        if (eS.isOK(loginGlobal)) {
          const a = await GETAuthStatus.get();

          if (a.loggedin === 0) { //Log them out of the app if there is any issue at all
            setLoginGlobal(POSTLogin.S0);
            nav("/app/login");
          }

        }
        else { //No login context - try getting it with auth.
          const res = await GETAuthUserInfo.get(); //ugly, auth, or OK

          eS.isOK(res) && setLoginGlobal(res);
          eS.isLogout(res) && nav("/app/login");
        }
      }
      F();
    }, []);

    const handleLogout = async () => {
        const res = await POSTLogout.post({});
        if (eS.isOK(res)) {
            setLoginGlobal(POSTLogin.S0);
            nav("/")
        }
    }

    return (    
    <AppBar position="static" style={{ backgroundColor: '#ccc' }}>
    <Toolbar style={{ minHeight: '60px', justifyContent: 'space-between' }}>
    <Link href="/app" style={{ textDecoration: 'none', color: 'black'}}>
      <Typography variant="h6">
        Palfrey 
      </Typography>
    </Link>
    <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ 
             color: 'black',
              marginRight: '20px',
              backgroundColor: '#eee',
              padding: '6px'
            }}>
            {`${loginGlobal.first_name} ${loginGlobal.last_name}`}
          </Typography>

          <Button color="inherit" onClick={handleLogout}>Logout</Button>
    </div>
    </Toolbar>
  </AppBar>)
}
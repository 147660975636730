import { Button, Card, CardContent, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { eState, preState } from "../apiCalls/metacall";
import { PropsOne } from "../meta";
import { useNavigate, useParams } from "react-router-dom";

const DepartmentBox = ({ data } : PropsOne<preState>) => {

    const nav = useNavigate();
    const {instId} = useParams();
    const clickLink = `/app/${instId}/dept/${data.id}`;
  
    return (
      <Grid container spacing={3} component={Card} onClick={() => nav(clickLink)}>
        {/* Upper Row */}
        <Grid item container justifyContent="space-between" xs={12}>
          <Grid item>
            <Typography variant="h5" onClick={() => nav(clickLink)}>
              {data.name}
            </Typography>
          </Grid>
        </Grid>
  
        {/* Bottom Row */}
        <Grid item container spacing={3} xs={12}>
          {data.stats && (
            <Grid item xs={6} md={8}>
              <StatsBox data={data.stats} />
            </Grid>
          )}
          <Grid item xs={6} md={4}>
            <ApproversTable data={data.approvers} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  
  // Approvers Table Component
  const ApproversTable = ({data}: PropsOne<preState>) => {
    return (
      <List>
        {data.map((approver: preState) => (
          <ListItem key={approver.pos_num}>
            <ListItemText 
              primary={`${approver.pos_num/10} - ${approver.first_name} ${approver.last_name}`}
              secondary={approver.email}
            />
          </ListItem>
        ))}
      </List>
    );
  }
  
  // Stats Box Component
  const StatsBox = ({data}: PropsOne<preState>) => {
    return (
        <Grid container spacing={2}>
          <Grid item xs={9}>
              <MetricCard number={data.N_active} description="Active tickets" color="green" isBold={true} />
              {/* Statuses can be another grid or simple flex layout */}
          </Grid>
          <Grid item>
              <MetricCard number={data.N_completed} description="Completed" />
              <MetricCard number={data.N_rejected} description="Rejected" color="red"/>
          </Grid>
        </Grid>
    );
  }
  
  function MetricCard ({ number = 0, description = "", color = 'black', isBold = false }) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h4" style={{ color, fontWeight: isBold ? 'bold' : 'normal' }}>
                    {number}
                </Typography>
                <Typography variant="subtitle1">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
  }
  
  
  //
  //END DEPARTMENT SUMMARY SUBCOMPONENTS
  //
  
  export function DeptsSummary({data}: PropsOne<eState>) {
  
      return (data._e.success ? 
              data.objs.map((d: any) => {
                return <DepartmentBox data = {d} key = {d.id}/>
              }
              )
              :
              <></>
              );
  }
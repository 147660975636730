import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import samoIcon from '../icons/samo.jpg'; // Replace this with your actual logo icon
import { GETAuthStatus, GETAuthUserInfo, POSTLogin, POSTLogout } from '../apiCalls/call';
import { loginResContext } from "../App";
import { eS } from '../apiCalls/metacall';
import { Link } from '@mui/material';

export function Header ({ isLoggedIn, userName }: any) {

    const {loginGlobal, setLoginGlobal} = useContext(loginResContext); //Has name

    useEffect(() => { //Treat context like a session or cookie.
      const F = async () => {
        if (eS.isOK(loginGlobal)) {
          const a = await GETAuthStatus.get();

          (a.loggedin === 0) && setLoginGlobal(POSTLogin.S0);

        }
        else { //No login context - try getting it with auth.
          const res = await GETAuthUserInfo.get();

          eS.isOK(res) && setLoginGlobal(res);
        }
      }
      F();
    }, []);

    const handleLogout = async () => {
        const res = await POSTLogout.post({});
        eS.isOK(res) && setLoginGlobal(POSTLogin.S0);
    }

    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="logo">
            <img src={samoIcon} alt="logo" style={{width: '40px', height: '40px'}}/>
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Palfrey
          </Typography>
  
          {eS.isOK(loginGlobal) ? (
            <>
              <Link href="/app" style={{ textDecoration: 'none', color: 'black'}}>
              <Typography variant="body1" style = {{marginRight: "16px", backgroundColor: "#66bbcc", padding: "8px"}}>
                Logged in as {loginGlobal.first_name + " " + loginGlobal.last_name}
              </Typography>
              </Link>
              <Button color="inherit" href="/app">Go to App</Button>
              <Button color="inherit" onClick = {handleLogout}>Log out</Button>
            </>
          ) : (
            <>
              <Button color="inherit" href="/app/login">Login</Button>
              <Button color="inherit" href="/app/signup">Sign Up</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  };

export function LandingPage() {


    const el: JSX.Element = <>
      <Header/>

      {/* Begin: chat-GPT generated space filler*/}

      <div style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to Palfrey Software Configurations
        </Typography>
        <Typography variant="body1" paragraph>
          At Palfrey, we specialize in providing top-of-the-line software configurations tailored to meet the unique needs of different departments within an organization.
        </Typography>
        <Typography variant="body1" paragraph>
          Our Sales Configuration Suite allows you to streamline the lead-to-customer conversion process, providing tools to automate mundane tasks and analytics to gauge performance.
        </Typography>
        <Typography variant="body1" paragraph>
          The Legal Configuration Suite offers a robust set of tools to manage contracts, compliance, and case studies, all in a secure environment that meets industry regulations.
        </Typography>
        <Typography variant="body1" paragraph>
          Last but not least, our HR Configuration Suite simplifies the human resources workflow from recruitment to retirement. Say goodbye to countless spreadsheets and hello to a centralized information hub.
        </Typography>

        {/* End: chat-GPT generated filler*/}
      </div>

    </>

    return el;
    
}
import { SyntheticEvent, useContext, useState } from "react";
import { POSTLogin } from "../apiCalls/call";
import { Button, Link, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { loginResContext } from "../App";
import { DynamicDisplayProps } from "../meta";
import { _eDefault, eS } from "../apiCalls/metacall";
import ErrorIcon from '@mui/icons-material/Error';
import '../output.css';
import { UglySnackbar } from "./SharedComps";


export function PrettyErrorText({isOpen, data}: DynamicDisplayProps<string>) {

  return (
    <>
    {isOpen && (<>
      <ErrorIcon color="error" />
      <Typography variant="body1" color="error">
        {data}
      </Typography></>)}

    </>
  )
}

export default function LoginPage() {

    const [loginBody, setLoginBody] = useState(POSTLogin.Body0);
    const [login, setLogin] = useState(POSTLogin.S0);
    const [uglyOpen, setUglyOpen] = useState(_eDefault);

    const nav = useNavigate();
    const {setLoginGlobal} = useContext(loginResContext);

    const handleSubmit = async (e: SyntheticEvent) => {
      e.preventDefault();
      const res = await POSTLogin.post(loginBody);

      if (eS.isUgly(res)) {
        setUglyOpen(res._e);
      }
      else if (eS.isOK(res)) { //Usually, OK and Pretty should be handled together;
        //but here, OK is redirect, and Auth error doesn't exist - anyone can attempt login.
        setLoginGlobal(res);
        nav("/app");
      }
      else {
        setLogin(res);
      }
    }

    const E = (<div className="flex flex-col h-screen justify-center items-center bg-gray-100">
    <div className = "p-8 bg-white rounded-lg shadow-md w-96">
    <h1 className = "text-2xl mb-4">Login</h1>
    <form onSubmit={handleSubmit}>
      <div className="text-center mt-4">
        <PrettyErrorText isOpen = {eS.isPretty(login)} data = {login._e.msg ?? ""}/>
      </div>
      <div className="mb-4">
        <TextField 
          fullWidth
          label="Email" 
          type="email"
          variant="outlined"
          value={loginBody.email}
          onChange = {(e) => setLoginBody({...loginBody, email: e.target.value})}
        />
      </div>
      <div className="mb-4">
        <TextField 
          fullWidth
          label="Password" 
          type="password"
          variant="outlined"
          value={loginBody.password}
          onChange={(e) => setLoginBody({...loginBody, password: e.target.value})}
        />
      </div>
      <div>
        <Button 
          type="submit"
          variant="contained" 
          color="primary"
          className="mb-4"
        >
          Login
        </Button>
      </div>
    </form>

      <UglySnackbar eDisp = {uglyOpen} setEDisp ={setUglyOpen}/>
    <div className = "text-center mt-4">
      <Link href="/app/signup">Sign Up</Link>
      <br />
      <a href="#!" onClick={() => alert('Functionality to be implemented')}>Forgot Password?</a>
    </div>
  </div>
  </div>
    );

    return E;
}


import React, { useContext, useState } from "react";
import { _eDefault, eS, eState, errorDisplay, preState } from "../apiCalls/metacall";
import { AppHeader, UglySnackbar } from "./SharedComps";
import { MyErrProps, PropsOne, formatISO8601, plural, useEffectCallAPIAsState } from "../meta";
import { useNavigate, useParams } from "react-router-dom";
import { GETDept, GETDeptApprovers, GETDeptBasicInfo, GETDeptPending, GETDeptRolePerms } from "../apiCalls/call";
import { Button, Divider, List, ListItem, ListItemText, ListSubheader, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { loginResContext } from "../App";
import MenuIcon from '@mui/icons-material/Menu';  // For View tickets
import MoveDownIcon from '@mui/icons-material/MoveDown';  // For Force-approve tickets
import OpenInNewIcon from '@mui/icons-material/OpenInNew';  // For Request new ticket
import GroupsIcon from '@mui/icons-material/Groups';  // For Edit approver list
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DescriptionIcon from '@mui/icons-material/Description';

export default function DeptPage() {

    const [uglyOpen, setUglyOpen] = useState(_eDefault);


    return (<><AppHeader/> 
    <TitleBarWrapper setEDisp = {setUglyOpen}/>
    <RoleAndActionContainer setEDisp = {setUglyOpen}/>
    <UglySnackbar eDisp = {uglyOpen} setEDisp= {setUglyOpen}/>
    </>);
}

function TitleBarWrapper({setEDisp} : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {

    const {instId, deptId} = useParams();
    const deptInfo = useEffectCallAPIAsState(GETDeptBasicInfo, {inst_id: instId, dept_id: deptId}, setEDisp);
    return (<><TitleBar deptInfo = {deptInfo} setEDisp = {setEDisp}/></>)
}

interface TitleBarProps {
  deptInfo: eState,
  setEDisp: React.Dispatch<React.SetStateAction<errorDisplay>>
}

function TitleBar({ deptInfo, setEDisp } : TitleBarProps) {

  const nav = useNavigate();

  //I want to use a MUI navigation component here
  return (<>
    <div style={{ padding: '10px 30px', backgroundColor: '#f0f0f0' }}>
      {/* Display inst_name in big, thin letters */}
      <h1 style={{ fontWeight: '300', fontSize: '36px' }}>{deptInfo.inst_name} / {deptInfo.name}</h1>
    </div></>
  );
}

//The problem is, I want a single API call to get the role and permissions;
//which means I have to pass the permissions down all the way to the data display table (can user req/approve)
function RoleAndActionContainer({ setEDisp } : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {

  const {instId, deptId} = useParams();
  const deptRolePermInfo = useEffectCallAPIAsState(GETDeptRolePerms, {inst_id: instId, dept_id: deptId}, setEDisp);


  return (<><RoleTitle deptInfo = {deptRolePermInfo} setEDisp = {setEDisp}/>
  <PendingAlert eDisp = {setEDisp}/>
  <ButtonBox data = {deptRolePermInfo.perms}/>
  <ApproversTableWrapper eDisp = {setEDisp}/>
  <Divider/>
  <TicketTableWrapper eDisp = {setEDisp} perms = {deptRolePermInfo.perms}/>
  </>)

}

function RoleTitle({ deptInfo, setEDisp } : TitleBarProps) {


  const roleInfo = (eS.isOK(deptInfo)) ? deptInfo.role.join(", ") : null;
  
  //const permInfo = (eS.isOK(deptInfo)) ? deptInfo.perms : [];

  return (
    !!roleInfo ?
    <div style = {{padding : '10px 30px', backgroundColor: '#f9f9f9'}}>
    <h2 style = {{ fontWeight: '500', fontSize: '24px'}}> Your department role: {roleInfo} </h2>
    </div>
    :
    <></>
  )

}

function PendingAlert({ eDisp } : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {
  const {instId, deptId} = useParams();
  const deptPendingInfo = useEffectCallAPIAsState(GETDeptPending, {inst_id: instId, dept_id: deptId}, eDisp);

  const deptPending = deptPendingInfo.dept;
  const selfPending = deptPendingInfo.self;

  return (<>
    { eS.isOK(deptPendingInfo) && 
      <>
        {
          !!deptPending && 
              <div style = {{padding: '8px 30px', backgroundColor: '#ffcccc'}}>
                <h3 style = {{ fontSize: '16px'}}>{plural(deptPending, "ticket")} from the department are pending your approval!</h3></div>
        }
        {
          !!selfPending && 
              <div style = {{padding: '8px 30px', backgroundColor: '#ccffff'}}>
                <h3 style = {{ fontSize: '16px'}}> {selfPending} of the tickets you requested is pending your approval!
                </h3></div>
        }
      </>
    }
  </>)

}

function ButtonBox ({data = [] as any[]}) {
  return (<div>
    {data.includes('dept.ticket.req') && (
        <Button
            variant="contained"
            style={{ marginLeft: '10px' }}
            startIcon={<OpenInNewIcon />}
        >
            Request new ticket
        </Button>
    )}

    {data.includes('dept.chain.edit') && (
        <Button
            variant="contained"
            color="inherit"
            style={{ marginLeft: '10px' }}
            startIcon={<GroupsIcon />}
        >
            Edit approver list
        </Button>
    )}
  </div>);
}

function ApproversTableWrapper({ eDisp } : PropsOne<React.Dispatch<React.SetStateAction<errorDisplay>>>) {
  const {instId, deptId} = useParams();
  const deptPendingInfo = useEffectCallAPIAsState(GETDeptApprovers, {inst_id: instId, dept_id: deptId}, eDisp);
  return (
    <ApproversTable data = {deptPendingInfo.objs}/>
  )
}

function ApproversTable ({data}: PropsOne<preState>) {
  return (
      (data && data.length > 0) ? 

      <List subheader={<ListSubheader>Department approvers</ListSubheader>}>
      {data.map((approver: preState) => (
        <ListItem key={approver.pos_num}>
          <ListItemText 
            primary={`${approver.pos_num/10} - ${approver.first_name} ${approver.last_name} <${approver.email}>`}
          />
        </ListItem>
      ))}
    </List>
    :
    <></>
  );
}

interface TicketTableWrapperProps {
  eDisp: React.Dispatch<React.SetStateAction<errorDisplay>>,
  perms: any[]
}

function TicketTableWrapper ({ eDisp, perms } : TicketTableWrapperProps) {
  const {instId, deptId} = useParams();
  const ticketSummaryES = useEffectCallAPIAsState(GETDept, {inst_id: instId, dept_id: deptId}, eDisp);

  const {loginGlobal : {email: userEmail, id: userID}} = useContext(loginResContext);

  const ticketSummary = ticketSummaryES.objs as any[] | undefined;

  const pendingTickets = ticketSummary ? ticketSummary.filter(x => (userEmail === x.next_approver_email)) : [];
  const selfTickets = ticketSummary ? ticketSummary.filter(x => (userID == x.requester && userEmail !== x.next_approver_email)) : [];

  const allOtherTickets = ticketSummary ? ticketSummary.filter(x => (userID != x.requester && userEmail !== x.next_approver_email)) : [];

  return ( 
    <>
      {
        pendingTickets.length > 0 ?
        <><Typography variant="h5" gutterBottom>
            Tickets pending your approval</Typography><TicketTable tickets={pendingTickets} perms={perms}/></>
        : <></>
      }
      {
        selfTickets.length > 0 ?
        <><Typography variant="h5" gutterBottom>
            Your tickets</Typography><TicketTable tickets={selfTickets} perms={perms}/></>
        : <></>
      }
      {
        allOtherTickets.length > 0 ?
        <><Typography variant="h5" gutterBottom>
            Department tickets</Typography><TicketTable tickets={allOtherTickets} perms={perms}/></>
        : <></>
      }
    </>
  )

}

function TicketTable ( {tickets = [] as any[], perms = [] as any[]} ) {
  return (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
      <TableRow>
        <TableCell>Requester</TableCell>
        <TableCell>Req. date</TableCell>
        <TableCell>Last update</TableCell>
        <TableCell>Software</TableCell>
        <TableCell>Title</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Next approver</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {tickets.map((row) => (
        <TableRow
          key={row.ticket_ID}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {row.requester_firstname} {row.requester_lastname}
          </TableCell>
          <TableCell>{formatISO8601(row.request_date)}</TableCell>
          <TableCell>{formatISO8601(row.last_update)}</TableCell>
          <TableCell>{row.software_name}</TableCell>
          <TableCell>{row.ticket_title}</TableCell>
          <TableCell>{row.ticket_status_description}</TableCell>
          <TableCell>{row.next_approver_firstname} {row.next_approver_lastname}</TableCell>
          <TableCell>
              <TicketButtonBox ticketInfo = {row} perms = {perms}/>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  </TableContainer>)
}

function TicketButtonBox ({ticketInfo = {} as any, perms = [] as any[]}) {
  /* Show accept quote button iff you have the perm, and you are next approver, and status is 100
              Show approve quote button iff you have the perm, and you are next approver, and status is 200
              Show force approver button iff you have the perm, and status is 100 or 200, and none of the above applies to you
              Show view ticket button in all cases
              */
    const {loginGlobal : {email: userEmail, id: userID}} = useContext(loginResContext);

    const canAcceptQuote = ticketInfo.ticket_status == 100 
                            && ticketInfo.next_approver_email === userEmail 
                            && perms.includes("dept.ticket.approve.self");

    const canApproveQuote = ticketInfo.ticket_status == 200 
                            && ticketInfo.next_approver_email === userEmail 
                            && perms.includes("dept.ticket.approve.dept");

    const canForceAccept = (ticketInfo.ticket_status == 100 || ticketInfo.ticket_status == 200)
                            && perms.includes("dept.ticket.forceapprove")
                            && !canAcceptQuote && !canApproveQuote
                            ;
    
    return (

      <>

      {
        canAcceptQuote ?
        <><Button
        variant="contained"
        style={{ marginLeft: '10px' }}
        startIcon={<CheckBoxIcon />}>
        Accept
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: '10px' }}
        startIcon={<DangerousIcon />}>
          Reject
        </Button></>
        : <></>
      }

      {
        canApproveQuote ?
        <><Button
        variant="contained"
        style={{ marginLeft: '10px' }}
        startIcon={<CheckBoxIcon />}>
        Approve
      </Button>
      <Button
        variant="contained"
        style={{ marginLeft: '10px' }}
        startIcon={<DangerousIcon />}>
          Reject
        </Button></>
        : <></>
      }

      {
      canForceAccept ? 
        <><Button
          variant="contained"
          style={{ marginLeft: '10px' }}
          startIcon={<MoveDownIcon />}>
          Force approve
        </Button>
        <Button
          variant="contained"
          style={{ marginLeft: '10px' }}
          startIcon={<DeleteForeverIcon />}>
            Force reject
          </Button></>
        :
        <></>
      
      }
          <Button
          variant="contained"
          style={{ marginLeft: '10px' }}
          startIcon={<DescriptionIcon />}>
            Details
          </Button>
      </>

    )
}
import { getWrapper, postWrapper } from './metacall';


export const GETAuthStatus = new getWrapper({loggedin: 0, user: "", email: ""}, false, 
                                    "/authStatus");


export const POSTLogin = new postWrapper({email: "", password: "", setcookie: "1"},
                                        {id: 0, first_name: "", last_name: "", email: ""}, false, 
                                        "/login");

export const POSTLogout = new postWrapper({}, {}, false, "/logout");

export const GETAuthUserInfo = new getWrapper({id: 0, first_name: "", last_name: "", email: ""}, false, "/authUserInfo");

export const GETInsts = new getWrapper({inst_id: 0, inst_name: "", role_name: "", dept_roles: []}, true, "/getInsts");

export const GETInstRoleAndPerms = new getWrapper({inst_id: 0, inst_name: "", role_name: "", dept_roles: [], perms: []}, false, "/getInst/rolesAndPerms")

export const GETInst = new getWrapper({id: 0, name: "", approvers: [], perms: [], stats: {}}, true, "/getInst");

export const GETInstUserList = new getWrapper({user_id: 0, email: "", first_name: "", last_name: "", time_added: "", role_id: 0, role_name_EN: ""}, true, "/getUserList");

export const GETInstUserListModPerms = new getWrapper({membershipPerm: [], moteto: [], motefrom: []}, false, "/getUserList/modPerms");

export const GETDept = new getWrapper({ticket_ID: 0, last_update: "", software_name: "", 
                                        ticket_title: "", ticket_desc: "", 
                                        requester: 0, requester_email: "", requester_firstname: "", requester_lastname: "", 
                                        is_active: 0, ticket_status: 0, ticket_status_description: "", 
                                        next_approver_email: "", next_approver_firstname: "", next_approver_lastname: "", request_date: ""}, true, "/getDept");
                                    
export const GETDeptPending = new getWrapper({self: 0, dept: 0}, false, "/getDept/pending");

export const GETDeptBasicInfo = new getWrapper({inst_name: "", name: "", time_added: ""}, false, "/getDept/basicInfo")

export const GETDeptRolePerms = new getWrapper({role: "", perms: []}, false, "/getDept/rolesAndPerms")

export const GETDeptApprovers = new getWrapper({first_name: "", last_name: "", email: "", pos_num: "", is_primary: ""}, true, "/getDept/approvers");

export const GETTicket = new getWrapper({perms: [], ticketdetails: {}, quotes: [], approvals: [], workprogress: []}, false, "/getTicket");
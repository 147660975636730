import { useEffect } from "react";
import { Props } from "../meta";

interface PageProps extends Props {
    title: string,
    component: React.ElementType
}

export default function Page({title, component: PageComponent}: PageProps) {
    useEffect(
        () => {
            document.title = title;
        },
        [title]
    );
    return <PageComponent/>;
}
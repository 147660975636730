import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const endpointRoot = "https://samo.azurewebsites.net/api"

export class endpointWrapper {
    actionType: 'GET' | 'POST' | 'PATCH'
    endpointRoute: string //Needs initial slash!

    //For result parsing and returning
    __S0: preState;
    S0: eState;
    listReturn: boolean;
    objBlueprint: preState;

    //if listThereof is true, then we are expecting the endpoint to return a list [] of objects.
    //Therefore, __S0 will be {objs: []}, and S0 will be {objs: [], _e: _eDefault}.

    constructor (actiontype: 'GET' | 'POST' | 'PATCH', endpointRoute: string, defaultOutStateObj: preState, listThereof: boolean) {
        this.actionType = actiontype;
        this.endpointRoute = endpointRoute;
        this.listReturn = listThereof;

        if (listThereof) {
            this.__S0 = {objs: []};
            this.objBlueprint = defaultOutStateObj;
        }
        else {
            this.__S0 = defaultOutStateObj;
            this.objBlueprint = {}; //Won't be used
        }


        this.S0 = e(defaultOutStateObj);
    }

    newS0() {
        return e({...this.__S0});
    }

    parse(res: AxiosResponse) {
        if (this.listReturn) {
            return parseResultList(res, this.objBlueprint);
        }
        else {
            return parseResultCurly(res, this.__S0);
        }
    }

    async go(params: preState = {}): Promise<eState> {
        throw new Error("Unimplemented abstract method!")
    }
}

export class getWrapper extends endpointWrapper {

    constructor(defaultOutStateObj: preState, listThereof: boolean, endpointRoute: string) {
        super('GET', endpointRoute, defaultOutStateObj, listThereof);
    }

    async get(params: preState = {}) {
        try {
            const reqConfig: AxiosRequestConfig = {withCredentials: true, params: params};
            const res = await axios.get(endpointRoot + this.endpointRoute, reqConfig);
            return this.parse(res);
        }
        catch (e) { 
            return handleAxiosErr(e, this.__S0);
        }
    }

    async go(params: preState = {}) {
        return await this.get(params);
    }
}

export class postWrapper extends endpointWrapper {

    Body0: preState;

    constructor(defaultPostIn: preState, defaultOut: preState, listThereof: boolean, endpointRoute: string) {
        super('POST', endpointRoute, defaultOut, listThereof);
        this.Body0 = defaultPostIn;
    }

    async post(postbody: preState, params: preState = {}) {
        try {
            const reqConfig: AxiosRequestConfig = {withCredentials: true, params: params};
            const res = await axios.post(endpointRoot + this.endpointRoute, postbody, reqConfig);
            return this.parse(res);
        }
        catch (e) {
            return handleAxiosErr(e, this.__S0);
        }
    }

    async go(params: preState = {}) {
        return await this.post(params);
    }
}

//Error handling

export type errorDisplay = {
    success: boolean,
    err: boolean,
    t?: "pretty" | "ugly" | "login",
    msg?: string
}

export const _eDefault: errorDisplay = {err: false, success: false}

export interface preState {
    [key: string]: any
}

export type eState = preState & {
    _e: errorDisplay
}

export function e(statyobj: preState): eState {
    return {...statyobj, _e: {..._eDefault}};
}

export function ok(statyobj: preState): eState {
    return {...statyobj, _e: {..._eDefault, success: true}}
}

//Add an error onto a preState, to make an errory result (eState)
export function addErr(statyobj: preState, t: "pretty" | "ugly" | "login", msg: string): eState {
    return {...statyobj, _e: {err: true, t: t, msg: msg, success: false}};
}


export function parseResultCurly(res: AxiosResponse, defaultPreState: preState): eState {
    const data = res.data;
    
    const newParsedResult = {...defaultPreState};

    for (let k in defaultPreState) {
        if (data[k]) {
            if (typeof defaultPreState[k] === 'number') {
                newParsedResult[k] = Number(data[k]);
            }
            else {
                newParsedResult[k] = deepClone(data[k]);
            }
        }
    }
    return ok(newParsedResult);
}

export function parseResultList(res: AxiosResponse, defaultPreState: preState): eState {
    const data = res.data;

    const newParsedResult: preState = {objs: []};

    for (let o of data) {

        const new_o: preState = {};

        for (let k in defaultPreState) {
            if (o[k]) {
                if (typeof defaultPreState[k] === "number") {
                    new_o[k] = Number(o[k]);
                }
                else {
                    new_o[k] = deepClone(o[k]);
                }
            }
        }

        newParsedResult.objs.push(new_o);
    }

    return ok(newParsedResult); //Will return a shallow copy, i.e. will retain the reference to the objs
}

//deepClone a str, number, date, array or obj. From stackexchange
function deepClone(obj: any) {
    var copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepClone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) 
            {copy[attr] = deepClone(obj[attr])};
        }
        return copy;
    }
}


//Generate a staty result with an error property, using a default state object 
//and the error that was thrown from Axios
export function handleAxiosErr(e: any, defaultPreState: preState): eState {

    const S = defaultPreState;

    if (e instanceof AxiosError) {
        if (e.response) { //Non 2xx response, esp. 4xx or 5xx
            if (e.response.status === 400) {
                return addErr(S, e.response.data.userError ? "pretty" : "ugly", e.response.data.error);
            }
            else if (e.response.status === 401) {
                return addErr(S, "login", e.response.data.error);
            }
            else { //For 500, 403 (and other things that I don't even know about)
                return addErr(S, "ugly", e.response.data.error);
            }
        }
        else if (e.request) {
            return addErr(S, "ugly", "Could not connect to server!")
        }
        else {
            return addErr(S, "ugly", "Web app error: " + e.message)
        }
    }
    else {
        return addErr(S, "ugly", "Unknown web app error.")
    };
}

//See if an eState has an ugly error
function isUgly(x: eState) {
    return (x._e.err && x._e.t === "ugly");
}

//See if an eState has a pretty error
function isPretty(x: eState) {
    return (x._e.err && x._e.t === "pretty" && x._e.hasOwnProperty("msg"));
}

function isLogout(x: eState) {
    return (x._e.err && x._e.t === "login");
}

function isOK(x: eState) {
    return (x._e.success);
}

export const eS = {
    isUgly: isUgly,
    isPretty: isPretty,
    isLogout: isLogout,
    isOK: isOK
}
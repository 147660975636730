import { ReactNode, useEffect, useState } from "react";
import { eS, eState, endpointWrapper, errorDisplay, preState } from "./apiCalls/metacall";
import { useNavigate } from "react-router-dom";

export interface Props {
    children?: ReactNode,
    [key: string] : any
}

export interface PropsOne<T> {
    [key: string]: T
}

export interface StateProps<T> {
    stateAttr: T,
    setStateAttr: React.Dispatch<React.SetStateAction<T>>;
}

export interface MyErrProps {
    eDisp: errorDisplay,
    setEDisp: React.Dispatch<React.SetStateAction<errorDisplay>>;
}

export interface DynamicDisplayProps<T> {
    isOpen: boolean,
    data: T
}

export function useEffectCallAPIAsState(endpoint: endpointWrapper, params: preState, setUgly: React.Dispatch<React.SetStateAction<errorDisplay>>) {

    const [ApiRes, setApiRes] = useState(endpoint.S0);
  
    const nav = useNavigate();
  
    useEffect(() => {
        const F = async () => {
            const res = await endpoint.go(params);
  
            if (eS.isOK(res)) {
                setApiRes(res);
            }
            else if (eS.isLogout(res)) {
                nav('/app/login');
            }
            else if (eS.isUgly(res)) {
                setUgly(res._e);
            }
        };
        F();
    }, []);
  
  
    return (ApiRes);
  }

export function plural(n: Number, word: String) {
    return n == 1 ? `1 ${word}` : `${n} ${word}s`
}

export function formatISO8601(date: string) {
    const x = new Date(date);
    return x.toLocaleDateString() + ' ' + x.toLocaleTimeString();
}